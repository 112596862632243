import Vue from 'vue'
import { firestorePlugin } from 'vuefire'
import firebase from 'firebase'
import 'firebase/firestore'

Vue.use(firestorePlugin)

// Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyAvYYLymrKn5Fj1rC16vH2dmtdp0AWqw5w',
  authDomain: 'turtlecreek-2019.firebaseapp.com',
  databaseURL: 'https://turtlecreek-2019.firebaseio.com',
  projectId: 'turtlecreek-2019',
  storageBucket: 'turtlecreek-2019.appspot.com',
  messagingSenderId: '678018239548',
  appId: '1:678018239548:web:d2fdbe6803528c83',
}
// Initialize Firebase
firebase.initializeApp(firebaseConfig)

// firebase utils
const auth = firebase.auth()
const db = firebase.firestore()
const storage = firebase.storage()
const currentUser = auth.currentUser
const timestamp = firebase.firestore.Timestamp

// firebase collections
const usersCollection = db.collection('users')
const ordersCollection = db.collection('orders')
const reviewsCollection = db.collection('reviews')

const productCollection = db.collection('products')
const customerCollection = db.collection('customers')
const reservationCollection = db.collection('reservation')

const shippingCollection = db.collection('shipping')
const discountCollection = db.collection('discounts')
const caresheetCollection = db.collection('caresheets')
const carouselSlidesCollection = db.collection('carouselSlides')

export {
  db,
  auth,
  storage,
  timestamp,
  currentUser,
  usersCollection,
  ordersCollection,
  reviewsCollection,
  productCollection,
  shippingCollection,
  discountCollection,
  customerCollection,
  caresheetCollection,
  reservationCollection,
  carouselSlidesCollection,
}

export const navigationRoutes = {
  root: {
    name: '/',
    displayName: 'navigationRoutes.home',
  },
  routes: [
    {
      name: 'dashboard',
      displayName: 'menu.dashboard',
      meta: {
        iconClass: 'vuestic-iconset vuestic-iconset-statistics',
      },
    },

    {
      name: 'orders',
      displayName: 'Orders',
      meta: {
        iconClass: 'fa fa-truck',
      },
    },

    {
      name: 'products',
      displayName: 'Products',
      meta: {
        iconClass: 'fa fa-tags',
      },
    },

    {
      name: 'caresheets',
      displayName: 'Caresheets',
      meta: {
        iconClass: 'fa fa-book',
      },
    },

    {
      name: 'customers',
      displayName: 'Customers',
      meta: {
        iconClass: 'fa fa-users',
      },
    },

    {
      name: 'reviews',
      displayName: 'Reviews',
      meta: {
        iconClass: 'fa fa-book',
      },
    },

    {
      name: 'settings',
      displayName: 'Settings',
      meta: {
        iconClass: 'fa fa-cogs',
      },
      children: [
        {
          name: 'discounts',
          displayName: 'Discounts',
          meta: {
            iconClass: 'fa fa-dollar',
          },
        },
        {
          name: 'landingCarousel',
          displayName: 'Landing Carousel',
          meta: {
            iconClass: 'fa fa-truck',
          },
        },
        {
          name: 'shipping',
          displayName: 'Shipping',
          meta: {
            iconClass: 'fa fa-truck',
          },
        },
      ],
    },

    {
      name: 'login',
      displayName: 'Logout',
      meta: {
        iconClass: 'vuestic-iconset vuestic-iconset-auth',
      },
    },
    /*

    {
      name: 'pages',
      displayName: 'menu.pages',
      meta: {
        iconClass: 'vuestic-iconset vuestic-iconset-files',
      },
      disabled: true,
      children: [
        {
          name: 'login',
          displayName: 'Login/Signup',
        },
        {
          name: '404-pages',
          displayName: '404 Pages',
        },
      ],
    }, */
  ],
}

import Vue from 'vue'
import Router from 'vue-router'
import AuthLayout from '../components_tc/auth/AuthLayout'
import AppLayout from '../components/admin/AppLayout'

import firebase from 'firebase'

Vue.use(Router)

const EmptyParentComponent = {
  template: '<router-view></router-view>',
}

const router = new Router({
  mode: process.env.VUE_APP_ROUTER_MODE_HISTORY === 'true' ? 'history' : 'hash',
  routes: [
    {
      path: '*',
      redirect: { name: 'dashboard' },
    },
    {
      path: '/auth',
      component: AuthLayout,
      children: [
        {
          name: 'login',
          path: 'login',
          component: () => import('../components_tc/auth/Login.vue'),
        },
        {
          name: 'recover-password',
          path: 'recover-password',
          component: () => import('../components_tc/auth/RecoverPassword.vue'),
        },
        {
          path: '',
          redirect: { name: 'login' },
        },
      ],
    },
    {
      path: '/404',
      component: EmptyParentComponent,
      children: [
        {
          name: 'not-found',
          path: '/pages/not-found',
          component: () => import('../components/pages/404-pages/VaPageNotFoundLargeText.vue'),
        },
      ],
    },
    {
      name: 'Admin',
      path: '/admin',
      component: AppLayout,
      children: [
        {
          name: 'dashboard',
          path: 'dashboard',
          component: () => import('../components_tc/dashboard/Dashboard.vue'),
          default: true,
          meta: {
            adminAuth: true,
          },
        },
        {
          name: 'orders',
          path: 'orders',
          component: () => import('../components_tc/orders/Orders.vue'),
          meta: {
            adminAuth: true,
          },
        },
        {
          name: 'products',
          path: 'products',
          component: () => import('../components_tc/products/Products.vue'),
          meta: {
            adminAuth: true,
          },
          children: [
            {
              name: 'ProductPage',
              path: 'ProductPage',
              component: () => import('../components_tc/products/ProductPage.vue'),
              meta: {
                adminAuth: true,
              },
            },
          ],
        },
        {
          name: 'caresheets',
          path: 'caresheets',
          component: () => import('../components_tc/caresheets/Caresheets.vue'),
          meta: {
            adminAuth: true,
          },
        },
        {
          name: 'customers',
          path: 'customers',
          component: () => import('../components_tc/customers/Customers.vue'),
          meta: {
            adminAuth: true,
          },
        },

        {
          name: 'reviews',
          path: 'reviews',
          component: () => import('../components_tc/reviews/Reviews.vue'),
          meta: {
            adminAuth: true,
          },
        },

        {
          name: 'settings',
          path: 'settings',
          component: EmptyParentComponent,
          children: [
            {
              name: 'discounts',
              path: 'discounts',
              component: () => import('../components_tc/settings/Discounts.vue'),
              meta: {
                adminAuth: true,
              },
            },
            {
              name: 'shipping',
              path: 'shipping',
              component: () => import('../components_tc/settings/Shipping.vue'),
              meta: {
                adminAuth: true,
              },
            },
            {
              name: 'landingCarousel',
              path: 'landingCarousel',
              component: () => import('../components_tc/settings/LandingCarousel.vue'),
              meta: {
                adminAuth: true,
              },
            },
          ],
        },
      ],
    },
  ],
})

router.beforeEach((to, from, next) => {
  const currentUser = firebase.auth().currentUser
  const adminAuth = to.matched.some(record => record.meta.adminAuth)

  if (adminAuth) {
    if (!currentUser) {
      next('/auth/login')
    } else if (currentUser.uid === 'MN4SXU3Kezb9FCpKD6syjCbgoXf2') {
      next()
    } else {
      next('/auth/login')
    }
  } else {
    next()
  }
})

export default router

class Account {
  constructor (email, fullname, uid) {
    this.email = email
    this.fullname = fullname
    this.uid = uid
  }

  getEmail () {
    return this.email
  }

  setEmail (email) {
    this.email = email
  }

  getFullname () {
    return this.fullname
  }

  setFullname (fullname) {
    this.fullname = fullname
  }

  getUID () {
    return this.uid
  }

  setUID (uid) {
    this.uid = uid
  }
}

class User extends Account {
  constructor (email, fullname, uid, address, marketing, orders, phone, type) {
    super(email, fullname, uid)
    this.address = address
    this.marketing = marketing
    this.orders = orders
    this.phone = phone
    this.type = type
  }

  getAddress () {
    return this.address
  }

  getMarketing () {
    return this.marketing
  }

  getOrders () {
    return this.orders
  }

  getPhone () {
    return this.phone
  }

  getType () {
    return this.type
  }

  setAddress (address) {
    this.address = address
  }

  setMarketing (marketing) {
    this.marketing = marketing
  }

  setOrder (orders) {
    this.orders = orders
  }

  setPhone (phone) {
    this.phone = phone
  }

  setType (type) {
    this.type = type
  }

  addOrder (order) {
    this.orders.push(order)
  }

  removeOrder (order) {
    var index = this.orders.indexOf(order)
    if (index === this.orders.length - 1) {
      this.orders.pop()
    } else if (index == null) {
      return 'Error no order'
    } else {
      this.orders.slice(index, index + 1)
    }
  }
}

export { Account, User }

import Vue from 'vue'
import Vuex from 'vuex'
import VuexI18n from 'vuex-i18n' // load vuex i18n module
import app from './modules/app'

import * as getters from './getters'
import { User } from '@/classes/Account.js'
import { usersCollection } from '@/firebaseConfig'

Vue.use(Vuex)

const store = new Vuex.Store({
  strict: false, // process.env.NODE_ENV !== 'production',
  getters,
  modules: {
    app,
  },
  state: {
    caresheets: [],
    customers: {
      customerData: [],
      topCustomer: null,
      topCustomerCountries: null,
    },
    discounts: [],
    orders: {
      ordersData: [],
      newOrders: 0,
      totalOrders: 0,
      uniqueCustomers: 0,
      totalRev: 0,
    },
    products: {
      productData: [],
      outOfStockItems: [],
      featuredItems: [],
    },
    user: {
      currentUser: null,
      userProfile: null,
      guestEmail: null,
    },
    reviews: {
      raiting: 0,
      reviewList: [],
    },
    shipping: [],
    landingCarouselSlides: [],
  },
  mutations: {
    setCurrentUser (state, val) {
      state.user.currentUser = val
    },

    setUserProfile (state, val) {
      state.user.userProfile = val
    },

    setGuestEmail (state, val) {
      state.user.guestEmail = val
    },

    setOrders (state, val) {
      state.orders = val
    },

    setProducts (state, val) {
      state.products = val
    },

    setProductData (state, val) {
      state.products.productData = val
    },

    setCaresheets (state, val) {
      state.caresheets = val
    },

    setCustomers (state, val) {
      state.customers = val
    },

    setDiscounts (state, val) {
      state.discounts = val
    },

    setShipping (state, val) {
      state.shipping = val
    },

    setLandingCarouselSlides (state, val) {
      state.landingCarouselSlides = val
    },

    setReviews (state, val) {
      state.reviews = val
    },
  },
  actions: {
    fetchUserProfile ({ commit, state }) {
      commit('setGuestEmail', null)
      var uid = state.user.currentUser.uid
      usersCollection
        .doc(uid)
        .get()
        .then(res => {
          const userData = res.data()
          var userObject = new User(
            userData.email,
            userData.fullname,
            userData.uid,
            userData.address,
            userData.marketing,
            userData.orders,
            userData.phone,
            userData.type,
          )
          commit('setUserProfile', userObject)
        })
        .catch(err => {
          alert(err.message)
        })
    },

    removeUserProfile ({ state }) {
      var uid = state.user.currentUser.uid
      usersCollection
        .doc(uid)
        .delete()
        .catch(err => {
          alert(err.message)
        })
    },
  },
})

Vue.use(VuexI18n.plugin, store)

export default store
